import { mapNodePeripherals } from "../components/pages/cluster/PeripheralsSection/helpers";

const colorMapping = {
    wiseome: "#06b6d4",
    tfmini: "#0284c7",
    Live: "#22c55e",
    Ready: "#28a745",
    Waiting: "#ffc107",
    Running: "#007bff",
    Completed: "#6f42c1",
    Error: "#dc3545",
    Success: "#28a745",
    Resolved: "#28a745",
    Acknowledged: "#7A3E21",
    Troubleshooting: "#6f8cf7",
    Training: "#874691",
    Installation: "#2CD0C2",
    ML02: "#f7d44a",
    ML03: "#cca51c",
    Cancelled: "#1E3F62",
    Online: "#16A34A",
    Connected: "#16A34A",
    Offline: "#DC2626",
    Disconnected: "#DC2626",
    "In maintenance": "#1298A9",
    "Not Configured": "#F87171",
    "Never detected": "#F87171",
    Missing: "#F87171",
    Yes: "#16A34A",
    No: "#F87171",
    "No Status": "#ABABAB",
    "Never seen": "#ABABAB",
    Gray: "#ABABAB",
    Orange: "#F59E0B",
    Red: "#DC2626",
    Green: "#16A34A",
    Blue: "#3B82F6",
    Manufacturing: "#5B9326",
    "Install In Progress": "#22c55e",
    Delivered: "#3AAFD2",
    UNKNOWN: "#ABABAB",
    UPGRADING: "#F59E0B",
    UP_TO_DATE: "#16A34A",
    unknown: "#8da9b7",
    lidar_connected: "#14BD57",
    lidar_disconnected: "#241C54",
    Raised: "#56C4BF",
    Escalated: "#447E88",
    Uncertain: "#F59E0B",
    DEV: "#22c55e",
    TEST: "#1298A9",
    UAT: "#F59E0B",
    PROD: "#DC2626",
};
const generateColor = (key = null): string => {
    if (key && colorMapping[key]) {
        return colorMapping[key];
    }
    const randomString = Math.floor(Math.random() * 0x7fffff).toString(16);
    const randomColor = "#" + randomString.padStart(6, "0");
    if (key) {
        colorMapping[key] = randomColor;
    }
    return randomColor;
};

const getUiStatus = (status: string, reachable: boolean): UIStatus => {
    let bgColour = "bg-gray-200";
    let label = "Offline";
    if (status === "Online" && reachable) {
        bgColour = "bg-green-600";
        label = "Online";
    } else if (status === "Offline" && reachable) {
        bgColour = "bg-red-600";
        label = "Offline";
    } else if (status === "Online" && reachable === false) {
        bgColour = "bg-orange-600";
        label = "Away";
    }

    return {
        bgColour,
        label,
    };
};

function timeAgo(dateString: string): string {
    if (!dateString) {
        return "-";
    }
    let sufix = "Z";
    if (dateString.endsWith("Z")) {
        sufix = "";
    }
    const serverDateUTC = new Date(dateString + sufix);
    const localNow = new Date();

    const diffInMilliseconds = localNow.getTime() - serverDateUTC.getTime();
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (isNaN(diffInMilliseconds)) {
        return "-";
    } else if (diffInSeconds < 60) {
        return `${diffInSeconds} seconds ago`;
    } else if (diffInMinutes < 60) {
        return `${diffInMinutes} minutes ago`;
    } else if (diffInHours < 24) {
        return `${diffInHours} hours ago`;
    } else {
        return `${diffInDays} days ago`;
    }
}

function formatDate(dateString: string): string {
    const serverDateUTC = new Date(dateString + "Z");

    if (isNaN(serverDateUTC.getTime())) {
        return "";
    }

    return serverDateUTC.toLocaleDateString();
}

function formatDateTime(dateString: string): string {
    const serverDateUTC = new Date(dateString + "Z");

    if (isNaN(serverDateUTC.getTime())) {
        return "";
    }

    return serverDateUTC.toLocaleString();
}

function generateUUID() {
    return crypto.randomUUID();
}

const getEnv = () => {
    const ret = window.location.href.match(
        /(localhost)|(test-portal\.maxiluxcloud\.com)|(portal\.maxiluxcloud\.com)|(portal\.maxiluxsystems-uat\.com)/
    )[0];
    return ret;
};
const isProd = getEnv() === "portal.maxiluxcloud.com";
const isTest = getEnv() === "test-portal.maxiluxcloud.com";
const isUat = getEnv() === "portal.maxiluxsystems-uat.com";

const configValue = (value: number | null | "", falback: number): number => {
    if (value === null || value === "") {
        return falback;
    }

    return value;
};

const formatDateForInput = (date: string | null): string => {
    if (!date) {
        return "";
    }
    const tmpDate = new Date(date);
    const year = tmpDate.getFullYear();
    const month = String(tmpDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(tmpDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
};

const systemStatus = (item: CoreSystem) => {
    if (!item?.status) {
        return "Never seen";
    }
    if (!item.reachable) {
        return "Disconnected";
    }

    if (item.highTraffic === true) {
        return "Uncertain";
    }

    if (item.status === "Offline") {
        return "Disconnected";
    }

    if (item.status === "Online") {
        return "Connected";
    }

    return item.status;
};

const uiSystemStatus = (
    status: string,
    reachable: boolean = false,
    highTraffic: null | boolean,
    maintenanceMode: null | boolean
) => {
    if (!status) {
        return "Never seen";
    }

    if (maintenanceMode === true) {
        return "In maintenance";
    }

    if (highTraffic === true) {
        return "Uncertain";
    }

    if (!reachable) {
        return "Disconnected";
    }

    if (status === "Offline") {
        return "Disconnected";
    }

    if (status === "Online") {
        return "Connected";
    }

    return status;
};

const nodeStatus = (item: CoreNode | CorePeripheral, system: CoreSystem) => {
    if (system.uiStatus === "Disconnected") {
        return "Disconnected";
    }

    if (!item?.status) {
        return "Missing";
    }
    if (!item.reachable) {
        return "Disconnected";
    }

    if (item.status === "Offline") {
        return "Disconnected";
    }

    if (item.status === "Online") {
        return "Connected";
    }

    return item.status;
};

const peripheralStatus = (item: CorePeripheral) => {
    if (!item?.status) {
        return "Missing";
    }
    if (!item.reachable) {
        return "Disconnected";
    }

    if (item.status === "Offline") {
        return "Disconnected";
    }

    if (item.status === "Online") {
        return "Connected";
    }

    return item.status;
};

const hideLoading = () => {
    const loadingElement = document.getElementById("loading");
    if (loadingElement) {
        loadingElement.style.display = "none";
        // loadingElement.remove(); //
    }
};
const showLoading = () => {
    const loadingElement = document.getElementById("loading");
    if (loadingElement) {
        loadingElement.style.display = "block";
    }
};

function filterSystem(system: CoreSystem): CoreSystem {
    const filteredSystem = JSON.parse(JSON.stringify(system));
    if (filteredSystem.nodes && filteredSystem.nodes.length > 0) {
        filteredSystem.nodes = filteredSystem.nodes.filter(
            (branchNode: CoreNode) => !branchNode.deleted
        ); // Filter out deleted branch nodes

        // Get structured peripherals for each node
        filteredSystem.nodes.map((branchNode: CoreNode) => {
            branchNode.uiPeripherals = mapNodePeripherals({
                node: branchNode,
            });
            return branchNode;
        });
    }

    return filteredSystem;
}

function sortPeripherals(system: CoreSystem): CoreSystem {
    const sortedSystem = JSON.parse(JSON.stringify(system));

    if (sortedSystem.nodes && sortedSystem.nodes.length > 0) {
        sortedSystem.nodes = sortedSystem.nodes
            .filter((branchNode: CoreNode) => !branchNode.deleted) // Filter out deleted branch nodes
            .map((branchNode: CoreNode) => {
                if (branchNode.nodes && branchNode.nodes.length > 0) {
                    branchNode.nodes = branchNode.nodes
                        .filter((leafNode: CoreNode) => !leafNode.deleted) // Filter out deleted leaf nodes
                        .map((leafNode: CoreNode) => {
                            if (
                                leafNode.peripherals &&
                                leafNode.peripherals.length > 0
                            ) {
                                // Filter out deleted peripherals
                                leafNode.peripherals = leafNode.peripherals
                                    .filter(
                                        (peripheral: CorePeripheral) =>
                                            !peripheral.deleted
                                    )
                                    .sort((a, b) =>
                                        a.address.localeCompare(b.address)
                                    );
                            }
                            return leafNode;
                        });
                }
                return branchNode;
            });
    }
    return sortedSystem;
}

function setAnalyticsEventProps({
    site,
    system,
    aisle,
    issue,
}: {
    site?: CoreSite;
    system?: CoreSystem;
    aisle?: CoreNode;
    issue?: CoreIssue;
}) {
    window.AnalyticsEventProperties = {
        siteRef: site?.reference,
        siteMaintenanceMode: site?.maintenanceMode,

        systemRef: system?.clientRef,
        systemMacAddress: system?.macAddress,
        systemMaintenanceMode: system?.maintenanceMode,
        systemCommissioned: system?.commissioned,
        systemCommissionedAt: system?.commissionedAt,

        aisleRef: aisle?.clientRef,
        aisleCommissioned: aisle?.commissioned,
        aisleReachable: aisle?.reachable,
        aisleAddress: aisle?.address,
        aisleSerial: aisle?.serial,

        issueType: issue?.issueType,
        issueNumber: issue?.number,
        issueRef: issue?.clientUserRef,
        issueLocation: issue?.location,
    };
}

function emitAnalyticsEvent(event: string) {
    window.GTMDataLayer?.push({ event: "customGtmEvent", eventType: event });
}


export {
    isProd,
    isTest,
    isUat,
    generateColor,
    getUiStatus,
    timeAgo,
    formatDate,
    formatDateTime,
    generateUUID,
    getEnv,
    configValue,
    formatDateForInput,
    uiSystemStatus,
    systemStatus,
    nodeStatus,
    peripheralStatus,
    hideLoading,
    showLoading,
    filterSystem,
    sortPeripherals,
    setAnalyticsEventProps,
    emitAnalyticsEvent,
};


