import { peripheralStatus } from "../../../../helpers/utils";

type MapNodePeripheralsProps = {
    node: CoreNode;
};

type ColumnType = "bag" | "ov" | "-";

const COLUMN_TYPE_BAG: ColumnType = "bag";
const COLUMN_TYPE_OV: ColumnType = "ov";
const STATUS_MISSING = "Missing";
const STATUS_OFFLINE = "Offline";
const STATUS_CONNECTED = "Connected";

const rowsStructure = (node: CoreNode): UiMappedPeripheral[] => {
    const rows = [];
    let peripheralIndex = 0;
    let leafNodeAddress = 0;

    const createEmptyRow = (
        column: number,
        address: number,
        columnType: ColumnType
    ) => {
        if (peripheralIndex % 2 === 0) {
            leafNodeAddress++;
        }

        rows.push({
            column: `${column}`,
            address: `${address}`,

            aliasAddress: `${node.address}.${leafNodeAddress}`,
            aliasSensorIndex: peripheralIndex % 2,
            columnType,

            model: "",
            status: STATUS_MISSING,
            lastUpdate: null,
            disabled: true,
        });
        peripheralIndex++;
    };

    // Normal columns
    for (let i = 0; i < node.configNoColumns; i++) {
        for (let j = 0; j < node.configNoSensors; j++) {
            createEmptyRow(i + 1, j + 1, COLUMN_TYPE_BAG);
        }
    }
    // OV columns
    for (
        let i = node.configNoColumns;
        i < node.configNoColumns + node.configNoColumnsOv;
        i++
    ) {
        for (let j = 0; j < node.configNoSensorsOv; j++) {
            createEmptyRow(i + 1, j + 1, COLUMN_TYPE_OV);
        }
    }

    return rows;
};

const transformAliasToRowIndex = (
    aliasAddress: string | null,
    aliasSensorIndex: number | null
) => {
    if (!aliasAddress) {
        return null;
    }

    return (parseInt(aliasAddress.split(".")[1]) - 1) * 2 + aliasSensorIndex;
};

const mappedRow = (
    peripheral: CorePeripheral,
    row: UiMappedPeripheral
): UiMappedPeripheral => {
    row.status = peripheralStatus(peripheral);
    row.lastUpdate = peripheral.updatedAt;
    row.model = peripheral.model || "";

    if (row.status !== STATUS_MISSING) {
        row.disabled = false;
    }
    if (peripheral.parentStatus === STATUS_OFFLINE) {
        row.disabled = true;
    }

    if (!peripheral.aliasAddress) {
        row.column = "-";
        row.address = "-";
    }

    return row;
};

const extraRow = (peripheral: CorePeripheral): UiMappedPeripheral => {
    return {
        column: `-`,
        address: `-`,

        aliasAddress: peripheral.aliasAddress,
        aliasSensorIndex: peripheral.aliasSensorIndex,
        columnType: "-",

        model: peripheral.model || "",
        status: peripheralStatus(peripheral),
        lastUpdate: peripheral.updatedAt,
        disabled: true,
    };
};

const mapNodePeripherals = ({
    node,
}: MapNodePeripheralsProps): UiMappedPeripherals => {
    const { mappedPeripherals } = node;

    const rows = rowsStructure(node);
    const extraRows = [];

    let lastKnownIndex = -1;
    mappedPeripherals.forEach((peripheral) => {
        let rowIndex = transformAliasToRowIndex(
            peripheral.aliasAddress,
            peripheral.aliasSensorIndex
        );


        if (rowIndex === null ||  !rows[rowIndex]) {
            // no address -> take the next one
            // ps: from now on all will should be without address

            // extra row -> add it to extra rows
            // finished with the rows -> add to extra rows

            const status = peripheralStatus(peripheral);
            if (status !== STATUS_CONNECTED) {
                // don't add it to the extra list unless it's connected
                return;
            }

            extraRows.push(extraRow(peripheral));
            return;
        }

        rows[rowIndex] = mappedRow(peripheral, rows[rowIndex]);
        lastKnownIndex = rowIndex;
    });

    return {
        peripheralsConfigured: rows,
        peripheralsNotConfigured: extraRows,
    };
};

export { mapNodePeripherals };
